<template>
  <div v-if="item">
    <v-card-text class="white text--primary">
      <div class="grey--text timeline-meta">{{ $t("Progress") }}</div>

      <h2 class="timeline-header">
        <router-link
          :to="{
            name: 'child_progress',
            params: { diary: diary.name, name: item.child.url_name },
          }"
          >{{ getHeader(item) | capitalize }}</router-link
        >
      </h2>

      <my-clamp
        :rows="5"
        :text="getContent(item)"
        class="timeline-body"
      ></my-clamp>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
  {
  "en": {
  "Progress": "Progress"
  },
  "sv": {
  "Progress": "Framsteg"
  }
  }
</i18n>

<script>
import MediaList from "../Helpers/MediaList.vue";
import { mapState } from "vuex";
import MyClamp from "@/components/global/MyClamp.vue";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    "media-list": MediaList,
    "my-clamp": MyClamp
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
    }),
  },
  methods: {
    getHeader(item) {
      if (item.event_data && item.event_data.event) {
        return item.event_data.event.header;
      }
      return "";
    },
    getContent(item) {
      if (item.event_data && item.event_data.event) {
        return item.event_data.event.description;
      }
      return "";
    },
  },
};
</script>