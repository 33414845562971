<template>
  <div v-if="item && item.child">
    <v-card-text class="white text--primary">
      <div>
        <div class="grey--text timeline-meta">{{ $t("Child") }}</div>

        <h2 class="timeline-header">
          {{ $t("BirthThisDay") }}{{ calculateAge() }}{{ $t("Years") }}
        </h2>
      </div>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
    {
    "en": {
    "Child": "Birthday",
    "BirthThisDay": "Turned ",
    "Years": " years old this day."
    },
    "sv": {
    "Child": "Födelsedag",
    "BirthThisDay": "Fyllde ",
    "Years": " år denna dag."
    }
    }
</i18n>

<script>
import MediaList from "../../TimelineItems/Helpers/MediaList.vue";
import { mapState } from "vuex";
import { dateHelpers } from "@/_helpers";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    "media-list": MediaList,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
    }),
  },
  methods: {
    openChild(child) {
      if (child && child.id) {
        this.$refs.childFeedModal.open(child.id);
      } else {
        this.$dialog.message.success(this.$t("ChildNotFound"), {
          position: "bottom-right",
        });
      }
    },
    calculateAge() {
      if (this.item.child) {
        return dateHelpers.getDateDiffFromDateObject(
          this.item.child.birth,
          this.item.event_date
        ).years;
      }
      return null;
    },
  },
};
</script>