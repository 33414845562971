<template>
  <div v-if="item">
    <v-card-text class="white text--primary">
      <div>
        <div class="grey--text timeline-meta">{{ $t("Memory") }}</div>

        <h2 class="timeline-header">
          <router-link
            :to="{
              name: 'diary_child_memories_read',
              params: {
                diary: diary.name,
                name: item.child.url_name,
                type: item.event_data.memory.url_name,
              },
            }"
            >{{ getHeader(item) | capitalize }}</router-link
          >
        </h2>

        <my-clamp
          :rows="5"
          :text="getContent(item)"
          class="timeline-body"
        ></my-clamp>
      </div>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
    {
    "en": {
    "Memory": "Memory"
    },
    "sv": {
    "Memory": "Minne"
    }
    }
</i18n>

<script>
import MediaList from "../Helpers/MediaList.vue";
import { mapState } from "vuex";
import { childMemories } from "../../../../../_helpers";
import MyClamp from "@/components/global/MyClamp.vue";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    "media-list": MediaList,
    "my-clamp": MyClamp
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
    }),
  },
  methods: {
    getHeader(item) {
      if (item.event_data && item.event_data.memory) {
        if (item.event_data.memory.title) return item.event_data.memory.title;

        var obj = childMemories
          .getList()
          .find((p) => p.key == item.event_data.memory.url_name);

        return obj.title;
      }
      return "";
    },
    getContent(item) {
      if (item.event_data && item.event_data.memory) {
        return item.event_data.memory.preamble;
      }
      return "";
    },
  },
};
</script>

<style>
</style>