<template>
  <div v-if="item">
    <v-card-text class="white text--primary">
      <div class="grey--text timeline-meta">{{ $t("LengthAndWeight") }}</div>

      <h2 class="timeline-header">
        <router-link
          :to="{
            name: 'child_growth',
            params: { diary: diary.name, name: item.child.url_name },
          }"
          >{{ getHeader(item) }}</router-link
        >
      </h2>

      <div v-if="item.event_data" class="timeline-body">
        {{ getContent(item) }}
      </div>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
  {
  "en": {
  "LengthAndWeight": "Growth",
  "Length": "Length",
  "And": "and",
  "Weight": "weight",
  "Headsize": "head circumference",
  "Missing": "is missing"
  },
  "sv": {
  "LengthAndWeight": "Tillväxt",
  "Length": "Längd",
  "And": "och",
  "Weight": "väger",
  "Headsize": "huvudomfång",
  "Missing": "saknas"
  }
  }
</i18n>

<script>
import MediaList from "../Helpers/MediaList.vue";
import { mapState } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
    }),
  },
  components: {
    "media-list": MediaList,
  },
  methods: {
    getHeader(item) {
      if (!item.event_data || !item.event_data.size) return "";

      var length =
        item.event_data.size.length + " " + item.event_data.size.length_format;
      var weight =
        item.event_data.size.weight + " " + item.event_data.size.weight_format;
      var headsize =
        item.event_data.size.headsize +
        " " +
        item.event_data.size.length_format;

      if (!item.event_data.size.length || item.event_data.size.length === 0)
        length = this.$t("Missing");

      if (!item.event_data.size.weight || item.event_data.size.weight === 0)
        weight = this.$t("Missing");

      if (!item.event_data.size.headsize || item.event_data.size.headsize === 0)
        headsize = this.$t("Missing");

      var txt = this.$t("Length") + " " + length;
      txt += ", " + this.$t("Weight") + " " + weight;
      txt +=
        " " + this.$t("And") + " " + this.$t("Headsize") + " " + headsize + ".";
      return txt;
    },
    getContent(item) {
      if (!item.event_data || !item.event_data.size) return "";

      return item.event_data.size.note;
    },
  },
};
</script>